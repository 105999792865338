import { make } from 'vuex-pathify'


const state = {
  currentList: [],

}



export default {

  namespaced: true,


  state,
  actions: make.actions(state),
  mutations: make.mutations(state),
  getters: {
    ...make.getters(state),
  }







}





