import { get as lsGet, set as lsSet } from "idb-keyval";
import { encode, decode } from "@msgpack/msgpack";

import api from "Helpers/apicalls.js";

import GitCommit from "../_git_commit";


import { memoPromise } from "Helpers/memopromise.js";


const memoizedFetch = memoPromise(api.get2, 5000)

class ReferenceData {
  constructor() { }
}

async function getReferenceData(type) {
  console.log("Getting referenceData: ", type);

  let referenceData = await lsGet("reference-data-" + type);

  if (
    !referenceData ||
    !referenceData._data ||
    referenceData._type != type ||
    referenceData._cacheExpiry < Date.now() ||
    GitCommit.version != (referenceData._version ?? "x")
  ) {


    referenceData = await memoizedFetch("school/reference/", true);

    if (!referenceData) {
      return {}
    }
    if (Object.keys(referenceData).length != 0) {



      const referenceDataO = {
        _data: encode(referenceData),
        _cacheExpiry: Date.now() + 300000,
        _version: GitCommit.version,
        _type: type,
      };

      await lsSet("reference-data-" + type, referenceDataO);

      let referenceDataObj = Object.assign(new ReferenceData(), referenceData);
      return referenceDataObj;
    }
    else {
      return {}
    }
  }


  let referenceDataObj = Object.assign(
    new ReferenceData(),
    decode(referenceData._data)
  );

  return referenceDataObj;
}

export { ReferenceData, getReferenceData };
