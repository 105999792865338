import { make } from 'vuex-pathify'


const state = {

  firebase: null,
  permissions: {}

}



export default {

  namespaced: true,


  state,
  actions: {

    ...make.actions(state)
  },
  mutations: {
    reset(localState) {
      Object.entries(state).forEach(v => {
        localState[v[0]] = v[1]
      })
    },

    ...make.mutations(state),
  },
  getters: {
    ...make.getters(state),
  }







}





