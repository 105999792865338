import RouterSchoolTimetable from "@/plugins/routers/school/timetable.js";
//import RouterSchoolLunch from "@/plugins/routers/school/lunch.js";
import RouterSchoolNextSteps from "@/plugins/routers/school/nextsteps.js";
import RouterSchoolConsents from "@/plugins/routers/school/consents.js";

export default [
  {
    path: "/school/",

    component: () => import("Views/School.vue"),
    meta: {
      requiresAuth: true,
      requiresDomain: true,
    },

    children: [
      {
        name: "schoolLunch",
        path: "/school/lunch",
        component: () => import("Views/School/Lunch.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
        }
      },


      RouterSchoolTimetable,
      RouterSchoolConsents,
      //RouterSchoolLunch,
      RouterSchoolNextSteps,
      {
        path: "/school/upload",

        component: () => import("Views/ExternalData/ExternalData.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
        children: [
          {
            path: "",
            name: "externalUpload",
            component: () =>
              import("Views/ExternalData/BackGroundFetchList.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
            },
          },
          {
            path: "/school/upload/file",
            name: "schoolUploadFile",
            component: () => import("Views/ExternalData/UploadFile.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
            },
          },

          {
            path: "/school/upload/sync",
            name: "schoolSyncFile",
            component: () => import("Views/ExternalData/BackGroundFetch.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
            },
          },

          {
            path: "/school/upload/file/edit/:id",
            name: "ExternalDataBackGroundFetchDetails",
            component: () =>
              import("Views/ExternalData/BackGroundFetchEdit.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
            },
          },
        ],
      },

      {
        path: "/school/chooser",
        name: "schoolChooser",
        component: () => import("Views/School/Chooser.vue"),
        meta: {
          requiresAuth: true,
          requiresDomains: true,
        },
      },

      {
        path: "/school/tutor/:gid+",
        name: "schoolTutorGroup",
        component: () => import("Views/School/Tutor.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },

      {
        path: "/school/message",
        name: "schoolMessaging",
        component: () => import("Views/School/MessageHome.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },
      {
        path: "/school/settings",
        name: "manageSettings",
        component: () => import("Views/School/Settings.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
          requiresAccessLevel: 100,
        }
      },
      {
        path: "/school/staff",
        name: "manageStaff",
        component: () => import("Views/School/Staff/Home.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
          requiresAccessLevel: 25,
        },
        redirect: { name: "manageStaff.list" },
        children: [
          {
            path: "list",
            name: "manageStaff.list",
            component: () => import("Views/School/Staff/List.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },
        ],
      },

      {
        path: "/school/links/manage/",
        name: "schoolLinksManager",
        component: () => import("Views/School/Links/Manage.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
          requiresAccessLevel: 25,
        },
      },



      {
        path: "/school/files/manage/",
        name: "schoolFilesManager",
        component: () => import("Views/School/FileManager.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
          requiresAccessLevel: 25,
        },
      },

      {
        path: "/school/student/search/",
        name: "pupilSearch",
        component: () => import("Views/School/StudentSearch.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },

      {
        path: "/school/student/attendance/",
        name: "pupilAttendance",
        component: () => import("Views/School/Attendance.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },




      {
        path: "/school/student/:sid+/threads",
        name: "pupilThreads",
        component: () => import("Views/School/Threads/StudentInfo/Home.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
        redirect: { name: "pupilThreads.List" },
        children: [
          {
            path: "list",
            name: "pupilThreads.List",
            component: () => import("Views/School/Threads/StudentInfo/List.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },
          {
            path: "create",
            name: "pupilThreads.Create",
            component: () => import("Views/School/Threads/StudentInfo/Create.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },
          {
            path: "view/:tid",
            name: "pupilThreads.View",
            component: () => import("Views/School/Threads/StudentInfo/View.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },
        ],
      },


      {
        path: "/school/student/:sid+/",
        name: "pupilInfo",
        component: () => import("Views/School/StudentInfo.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },

      {
        path: "/school/help/:id",
        name: "schoolHelp",
        component: () => import("Views/School/Help/ViewSelected.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },

      {
        path: "/school/datadump",
        name: "schoolDataDump",
        component: () => import("Views/School/CompleteDataDump.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },

      },

      {
        path: "/school/datadump/manage",
        name: "schoolDataDumpManage",
        component: () => import("Views/School/DataDump/Manage.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
          requiresAccessLevel: 25,
        },
        redirect: { name: "schoolDataDump.list" },
        children: [
          {
            path: "list",
            name: "schoolDataDump.list",
            component: () => import("Views/School/DataDump/List.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },


          {
            path: "/school/datadump/manage/:id",
            name: "schoolDataDump.view",
            component: () => import("Views/School/DataDump/View.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              requiresStaff: true,
              requiresAccessLevel: 25,
            },
          },
        ],
      },

      {
        path: "/school/register/missing",
        name: "schoolRegisterMissing",
        component: () => import("Views/School/RegisterMissing.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },

      {
        path: "/school/reports/",
        name: "schoolReports",
        component: () => import("Views/School/Report.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },
      {
        path: "/school/links/",
        name: "schoolLinks",
        component: () => import("Views/School/Links/View.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
          requiresStaff: true,
        },
      },
    ],
  },
];
